import { RouteLocationNormalizedLoaded } from 'vue-router';

const useGptStore = defineStore('gpt', () => {
  if (process.server) {
    return;
  }
  const { googletag } = window;
  const slots = ref<googletag.Slot[]>([]);

  const registerSlot = (slot: googletag.Slot) => slots.value.push(slot);

  const refreshAds = async () => {
    await nextTick();

    if (!slots.value.length) {
      return;
    }

    googletag.cmd.push(() => {
      googletag.pubads().enableLazyLoad();
      googletag.pubads().refresh(toRaw(slots.value));
    });

    slots.value = [];
  };

  return { registerSlot, refreshAds };
});

export { useGptStore };
