<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /**
     * The advert in the inventory
     */
    unit: string;
    /**
     * The ID of the wrapper element
     */
    id: string;
    /**
     * The category of the listing page or post (on category or post pages only)
     */
    category?: string | string[];
    /**
     * The slug of the post (on a post page only)
     */
    slug?: string;
  }>(),
  {
    category: '',
    slug: '',
  }
);

const gptId = 16304992;

type Slot = {
  adUnitPath: string;
  size: string[] | number[];
};

const inventory: Record<string, Slot> = {
  topBillboard: {
    adUnitPath: `/${gptId}/haystack-top-billboard`,
    size: window.innerWidth >= 768 ? [728, 90] : [320, 50],
  },
  sidebarHpu: {
    adUnitPath: `/${gptId}/haystack-sidebar-hpu`,
    size: [300, 600],
  },
  belowListingBanner: {
    adUnitPath: `/${gptId}/haystack-below-listing-banner`,
    size: ['fluid'],
  },
};

const { id, unit, category, slug } = props;

const adUnit = inventory[unit];

const { adUnitPath, size } = adUnit;

const { googletag } = window;

let slot: googletag.Slot;

const { registerSlot } = useGptStore();

onMounted(() => {
  googletag.cmd.push(() => {
    slot = googletag.defineSlot(
      adUnitPath,
      size as googletag.GeneralSize,
      id
    ) as googletag.Slot;

    slot
      // .setTargeting('AppCategor', category)
      // .setTargeting('AppSlug', String(slug))
      .setCollapseEmptyDiv(true)
      .addService(googletag.pubads());

    googletag.display(slot);
  });

  registerSlot(slot);
});

onBeforeUnmount(() => {
  googletag.cmd.push(() => {
    if (typeof googletag.destroySlots !== 'function') {
      return;
    }

    googletag.destroySlots([slot]);
  });
});
</script>

<template>
  <div :id="id" class="advert-slot" />
</template>

<style scoped lang="scss">
.advert-slot {
  background: #e5e5e5;
}
</style>
